body {
  background-color: #000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.background-gradient {
  background: linear-gradient(135deg, #13001e 0%, #000000 100%);
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
      2px 2px at 20px 30px,
      #fff,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 50px 160px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 130px 80px, #fff, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  animation: twinkle 4s ease-in-out infinite;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.hero-section {
  text-align: center;
  margin-bottom: 4rem;
}

.logo-container {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.App-logo {
  height: 180px;
  width: auto;
  position: relative;
  z-index: 2;
}

.glow-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: radial-gradient(
    circle,
    rgba(255, 20, 147, 0.2) 0%,
    rgba(255, 20, 147, 0) 70%
  );
  border-radius: 50%;
  animation: glow 3s ease-in-out infinite;
}

.floating {
  animation: floating 3s ease-in-out infinite;
}

.title {
  font-size: 4.5rem;
  margin: 1.5rem 0;
  font-weight: 800;
  letter-spacing: -0.5px;
}

.gradient-text {
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
}

.tagline {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 700px;
  margin: 0 auto;
}

.download-section {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 3rem 0;
}

.download-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 1.5rem;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;
  text-align: center;
}

.download-card:hover {
  transform: translateY(-5px);
}

.download-button {
  height: 60px;
  transition: all 0.3s ease;
}

.download-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.coming-soon-badge {
  background: linear-gradient(45deg, #ff1493, #ff69b4);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  margin-top: 1rem;
  text-align: center;
}

.badge-text {
  position: relative;
  z-index: 1;
}

.badge-glow {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shine 3s infinite;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 5rem;
}

.feature-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;
  text-align: center;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  color: #fff;
  margin: 1rem 0;
  font-size: 1.3rem;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.4;
}

/* Animations */
@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes glow {
  0%,
  100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(1.1);
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 200%;
  }
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .features {
    grid-template-columns: 1fr;
  }

  .download-section {
    flex-direction: column;
    align-items: center;
  }

  .download-card {
    width: 100%;
    max-width: 300px;
  }

  .subtitle {
    font-size: 1rem;
    padding: 0 1rem;
  }
}
